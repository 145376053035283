import React from 'react';
import Layout from '../components/layout';
import Resources from '../components/resources/Resources';

export default function resources() {
  return (
      <Layout>
        <Resources />
      </Layout>
  );
}
