import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import globalStyles from '../styles/Main.module.css';
import styles from '../styles/Resources.module.css';

const Resources = () => (
  <div className={globalStyles.section}>
    <h1 className={globalStyles.sectionTitle}>Resources</h1>
    <StaticQuery
      query={graphql`
        query {
          allContentfulResources {
            edges {
              node {
                resourceTitle
                resourcePdf {
                  file {
                    url
                    fileName
                    contentType
                  }
                }
              }
            }
          }
        }
      `}
      render={data => {
        return data.allContentfulResources.edges.map(e => {
          return (
            <a
              href={'https://' + e.node.resourcePdf.file.url}
              key={e.node.resourceTitle}
              className={styles.resourceLink}
            >
            <div className={styles.buttonDiv}>
              <button className={styles.resourceButton}>{e.node.resourceTitle}</button>
            </div>
            </a>
          );
        });
      }}
    />
  </div>
);

export default Resources;
